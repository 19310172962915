/* Can't style the html tag with theme-ui, so put styles in here */

html {
  height: 100%;
}

@font-face {
  font-family: Avenir;
  src: url('./assets/fonts/Avenir.ttc')  format('truetype');
}

@font-face {
  font-family: Neutra Display;
  src: url('./assets/fonts/NeutraDisplayTitling.ttf')  format('truetype');
}

.hidden-tooltip {
  visibility: hidden;
}